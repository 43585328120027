import { facility } from './hydrated-state'

const { VITE_ENVIRONMENT, VITE_VERSION } = import.meta.env

const sharedDatadogConfig = {
  clientToken: 'pub0bcc53e0713b5d639caa62bb255dbf30',
  site: 'datadoghq.com',
  service: 'patient',
  env: VITE_ENVIRONMENT,
  version: VITE_VERSION,
  sessionSampleRate: 100,
  storeContextsAcrossPages: true,
}

/**
 * Returns a timestamp string in a "HH:MM:SS" format.
 */
function getTimestamp(): string {
  const now = new Date()

  return [now.getHours(), now.getMinutes(), now.getSeconds()]
    .map(String)
    .map((chunk) => chunk.slice(0, 2))
    .map((chunk) => chunk.padStart(2, '0'))
    .join(':')
}

type StatusCode = 'error' | 'alert' | 'debug' | 'info' | 'warn' | 'ok' | 'notice' | 'critical' | 'emerg'

enum StatusCodeColor {
  Debug = 'inherit', // Use the default color for Debug
  Error = '#E95F5D',
  Info = '#89cff0',
  Warning = '#F0BB4B',
}

/**
 * Returns a HEX color for a given response status code number.
 */
function getStatusCodeColor(status: StatusCode): StatusCodeColor {
  if (status === 'debug') {
    return StatusCodeColor.Debug
  }

  if (['error', 'alert', 'critical', 'emerg'].includes(status)) {
    return StatusCodeColor.Error
  }

  if (['info', 'ok', 'notice'].includes(status)) {
    return StatusCodeColor.Info
  }

  return StatusCodeColor.Warning
}

export const initInstrumentation = async () => {
  if (VITE_ENVIRONMENT === 'production') {
    const { datadogRum } = await import('@datadog/browser-rum')

    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: 'c870695b-aa81-4b3a-954e-ae4f244a2a64',
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      workerUrl: new URL('@datadog/browser-worker', import.meta.url).href,
      defaultPrivacyLevel: 'mask-user-input',
      compressIntakeRequests: true,
      beforeSend(event) {
        // Redact authCode from the URLs
        if (event.view.url?.includes('authCode=')) {
          event.view.url = event.view.url.replace(/authCode=[^&]*/, 'authCode=REDACTED')
        }

        if (event.view.referrer?.includes('authCode=')) {
          event.view.referrer = event.view.referrer.replace(/authCode=[^&]*/, 'authCode=REDACTED')
        }

        if (event.type === 'error') {
          // Filter out unactionable CSP errors
          if (
            // Filter out CSP errors caused by Browser Extensions
            event.error?.stack?.includes('chrome-extension:') ||
            event.error?.stack?.includes('safari-extension:') ||
            event.error?.stack?.includes('safari-web-extension:') ||
            event.error?.stack?.includes('user-script:') ||
            // Filter out "font" errors for Google Fonts
            event.error?.message?.includes('https://fonts.gstatic.com/s/') ||
            // Filter out Facebook Connect errors
            event.error?.message?.includes('https://connect.facebook.net/en_US') ||
            // Filter out Google Translate errors
            event.error?.message?.includes('https://translate.google.com/') ||
            event.error?.message?.includes('https://www.gstatic.com/_/translate_http/')
          ) {
            return false
          }

          // Filter out Browser Extension errors
          if (event.error?.message?.includes('Invalid call to runtime.sendMessage(). Tab not found.')) {
            return false
          }

          // Filter out "Load failed" & "cancelled" errors
          if (event.error?.message?.includes('Load failed') || event.error?.message?.includes('cancelled')) {
            return false
          }
        }

        return true
      },
    })

    // Set Facility ID
    datadogRum.setGlobalContextProperty('facilityId', facility.id)
  }

  const { datadogLogs } = await import('@datadog/browser-logs')

  datadogLogs.init({
    ...sharedDatadogConfig,
    forwardErrorsToLogs: true,
    beforeSend: (log) => {
      // Redact authCode from the URLs
      if (log.view.url?.includes('authCode=')) {
        log.view.url = log.view.url.replace(/authCode=[^&]*/, 'authCode=REDACTED')
      }

      if (log.view.referrer?.includes('authCode=')) {
        log.view.referrer = log.view.referrer.replace(/authCode=[^&]*/, 'authCode=REDACTED')
      }

      // Filter out "TypeError: Load failed" logs, where the status code is 0
      // This is caused when a user navigates and the browser cancels the fetch request
      if (log.http?.status_code === 0) {
        return false
      }

      // Filter out logs caused by dynamic import/fetch/Apollo when we roll out a new version of the app,
      // which forces a refresh of the page
      if (log.view.url.includes('version_reload=true')) {
        if (
          log.message.includes('The string did not match the expected pattern.') ||
          log.message.includes('Importing a module script failed.') ||
          log.message.includes('Failed to fetch')
        ) {
          return false
        }
      }

      // Filter out Browser errors
      if (
        log.message === 'Timeout' ||
        log.message === 'Message Timeout' ||
        log.message.includes('Uncaught "Script error."') ||
        log.message.includes('Uncaught "Empty reason"') ||
        log.message.includes('Invalid call to runtime.sendMessage(). Tab not found.') ||
        // 1Password extension errors
        log.message.includes('Unhandled error response received for message <')
      ) {
        return false
      }

      // Filter out third-party errors
      if (
        log.error?.stack?.includes('chrome-extension:') ||
        log.message.includes("null is not an object (evaluating 'n.some')") ||
        log.message.includes("undefined is not an object (evaluating 'e.sdkVersion')") ||
        log.message.includes("Cannot read properties of null (reading 'shadowRoot')") ||
        // Transifex Native fetch errors
        (log.error?.stack?.includes('.fetchTranslations') && log.message.includes('Failed to fetch'))
      ) {
        return false
      }

      // Filter out fetch errors that are not actionable
      if (
        [
          'Fetch error POST',
          'XHR error POST',
          'cancelled',
          'Load failed',
          'NetworkError when attempting to fetch resource.',
          'The request timed out.',
        ].some((ignoredLog) => log.message.includes(ignoredLog) || log.error?.message?.includes(ignoredLog))
      ) {
        return false
      }

      // Filter out crawler errors (i.e Outlook Email crawler), see https://stackoverflow.com/questions/75536442/troubleshooting-non-error-promise-rejection-captured-with-value-object-not-fou
      if (log.message.includes('Object Not Found Matching Id:')) {
        return false
      }

      // In non-production environments, log to the console instead of Datadog
      if (VITE_ENVIRONMENT !== 'production') {
        console.groupCollapsed(
          `[DATADOG] ${getTimestamp()} \`${log.message}\` (%c${log.status.toUpperCase()}%c)`,
          `color:${getStatusCodeColor(log.status)};`,
          'color:inherit;',
        )
        console.log('Payload', log)
        console.groupEnd()

        return false
      }

      return true
    },
  })

  datadogLogs.onReady(() => {
    // In non-production environments, log to the console instead of Datadog
    if (VITE_ENVIRONMENT !== 'production') {
      console.groupCollapsed(
        '%c[DATADOG] Logs being intercepted for non-production environments',
        'color:yellow;font-weight:bold;',
      )
      console.log('Change in the `src/core-web/instrumentation.ts` file if needed')
      console.groupEnd()
    }
  })

  // Set Facility ID
  datadogLogs.setUserProperty('facilityId', facility.id)
}
