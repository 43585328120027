const STORAGE_KEY = 'vital:response'
const STORAGE_KEY_NEW = 'vital'

type LocalStorageKey =
  | `checklist-confirm-${string}`
  | `checklist-touched-${string}`
  | 'cached-languages'
  | 'has-accepted-tests-info-dialog'
  | 'id-token-expiry'
  | 'id-token'
  | 'language'
  | 'refresh-token'
  // TODO Remove 'tracking-id' once we ditch the user tracking
  | 'tracking-id'
  | 'user-types'
  // TODO Remove 'visit-id' once we ditch the user tracking
  | 'visit-id'
  | 'visit-type'

/**
 * Add to Local Storage
 */
export const addValueToLocalStorage = <S>(key: LocalStorageKey, value: S) => {
  // TODO: Remove this once we migrate to the new storage key (Jan 2025)
  localStorage.setItem(`${STORAGE_KEY}:${key}`, JSON.stringify(value))
  // Keep this new one!
  localStorage.setItem(`${STORAGE_KEY_NEW}:${key}`, JSON.stringify(value))
}

/**
 * Retrieve from Local Storage
 */
export const getValueFromLocalStorage = <S>(key: LocalStorageKey) => {
  try {
    // TODO: Migrate to the new storage key (Jan 2025)
    const item = localStorage.getItem(`${STORAGE_KEY}:${key}`)

    return item && item !== null ? (JSON.parse(item) as S) : undefined
  } catch {
    // If error, return undefined
    return undefined
  }
}
